import React, { useState, useEffect } from 'react';
import './AllTransactions.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
const AllTransactions = () => {
  const { t } = useTranslation('wallet'); // تحديد Namespace
  const [transactions, setTransactions] = useState([]);
  const navigate = useNavigate();



  // جلب البيانات من الـ API عند تحميل الصفحة
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('http://sanaddigital.com/public/api/payments', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // إذا كان هناك توكين في التطبيق
          },
        });
        const { individualPayments, individualPayouts } = response.data;

        // دمج بيانات الإيداع والسحب مع إضافة نوع العملية (Deposit أو Withdrawal)
        const allTransactions = [
          ...individualPayments.map((payment) => ({
            ...payment,
            type: 'Payment',
          })),
          ...individualPayouts.map((payout) => ({
            ...payout,
            type: 'Payout',
          })),
        ];
        
        allTransactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        // تحديث الحالة بالبيانات
        setTransactions(allTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();

    const intervalId = setInterval(() => {
      fetchTransactions();
    }, 10000);

    return () => clearInterval(intervalId);
  
  }, []);

  return (
    <div dir={t('direction')} className="user-transactions-container">
    <h2>{t('all_transactions')}</h2>
    <div className="transactions-list">
      {transactions.length === 0 ? (
        <div>{t('no_transactions_found')}</div>
      ) : (
        transactions.map((transaction) => (
          <div
            key={transaction.id}
            className={`transaction-card ${transaction.status.toLowerCase()}`}
          >
            <div className="transaction-info">
              <div className="transaction-type"> {t(`${transaction.type.toLowerCase()}`, { defaultValue: transaction.type })}</div>
              <div className="transaction-amount">
                {t('transaction_amount')} {transaction.amount}
              </div>
              <div className="transaction-date">
                {t('transaction_date')} {new Date(transaction.created_at).toLocaleDateString()}
              </div>
              <div className="transaction-time">
                {t('transaction_time')} {new Date(transaction.created_at).toLocaleTimeString()}
              </div>
              {t('transaction_status')}
              <div
                className={`transaction-status ${
                  transaction.status.toLowerCase() === 'paid'
                    ? 'status-paid'
                    : transaction.status.toLowerCase() === 'failed' ||
                      transaction.status.toLowerCase() === 'cancel'
                    ? 'status-failed'
                    : 'status-check'
                }`}
              >
                {transaction.status}
              </div>
              <div
                style={{ marginBottom: '20px' }}
                className="transaction-wallet"
              >
                {t('transaction_wallet')} {transaction.address || 'N/A'}
              </div>
            </div>
            {transaction.type === 'Payment' && transaction.url && (
              <a
                style={{
                  textDecoration: 'none',
                  color: 'white',
                  marginTop: '20px',
                }}
                href={transaction.url}
                target="_blank"
                rel="noopener noreferrer"
                className="view-details-btn"
              >
                {t('view_details')}
              </a>
            )}
          </div>
        ))
      )}
    </div>
  </div>
  
  );
};

export default AllTransactions;
