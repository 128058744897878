import React, { useState, useEffect } from 'react';
import './Signup.css';
import axios from 'axios'; 
import { Link, useNavigate, useLocation } from 'react-router-dom'; 
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useTranslation } from "react-i18next";
function SignUp() {
    const { t } = useTranslation("sign");
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [invitationLink, setInvitationLink] = useState(''); // تغيير اسم الحالة إلى invitationLink
    const navigate = useNavigate(); 
    const location = useLocation(); 

    useEffect(() => {
      // تحقق مما إذا كان المستخدم مسجل الدخول بالفعل
      const token = localStorage.getItem('token');
      if (token) {
          // إذا كان المستخدم مسجل الدخول، أعد التوجيه إلى الصفحة الحالية
          toast.info('You are already logged in!');
          navigate(-1); // يعيد المستخدم إلى الصفحة السابقة
      }
  }, [navigate]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const invitationParam = queryParams.get('invitation');
        if (invitationParam) {
            setInvitationLink(invitationParam);
        }
    }, [location]);

    const validateForm = async (event) => {
        event.preventDefault();
        setErrors({}); 

        let formErrors = {};
        let valid = true;

        if (!name) {
          formErrors.name = '*Name is required.';
          valid = false;
      } else if (!/^[a-zA-Z\s]+$/.test(name)) {
          formErrors.name = '*Name must contain only English letters.';
          valid = false;
      }

        if (!email) {
            formErrors.email = '*Email is required.';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formErrors.email = '*Please enter a valid email address.';
            valid = false;
        }

        if (!password) {
            formErrors.password = '*Password is required.';
            valid = false;
        } else if (password.length < 6) {
            formErrors.password = '*Password must be at least 6 characters.';
            valid = false;
        } else if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(password)) {
          formErrors.password = '*Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
          valid = false;
      }

        if (!confirmPassword) {
            formErrors.confirmPassword = '*Confirm Password is required.';
            valid = false;
        } else if (password !== confirmPassword) {
            formErrors.confirmPassword = '*Passwords do not match.';
            valid = false;
        }

        if (valid) {
            try {
                const response = await axios.post('http://sanaddigital.com/public/api/register', {
                    name: name,
                    email: email,
                    password: password,
                    invited_by: invitationLink,
                });

                toast.success('Registration successful! Redirecting to sign in...');
                navigate('/SignIn'); 
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('An error occurred. Please try again later.');
                }
            }
        } else {
            setErrors(formErrors);
        }
    };

    const togglePasswordVisibility = (id) => {
        const passwordField = document.getElementById(id);
        const type = passwordField.type === 'password' ? 'text' : 'password';
        passwordField.type = type;
    };

    return (
        <div className='body'>
            <div className="containerSU">
                <div className="image-section">
                    {/* Image will be here */}
                </div>
                <div dir={t('direction')} className="form-section">
      <h2>{t("sign_up")}</h2>
      <form id="sign-up-form" onSubmit={validateForm}>
        <div className="input-group">
          <label htmlFor="name">{t("full_name_label")}</label>
           <input dir="ltr"
            type="text"
            id="name"
            placeholder={t("full_name_placeholder")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
           <input dir="ltr" type="hidden" name="invitation" value={invitationLink} />
          <div className="error">{errors.name && t("error_message_name")}</div>
        </div>
        <div className="input-group">
          <label htmlFor="email">{t("email_label")}</label>
           <input dir="ltr"
            type="email"
            id="email"
            placeholder={t("email_placeholder")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="error">{errors.email && t("error_message_email")}</div>
        </div>
        <div className="input-group">
          <label htmlFor="password">{t("password_label")}</label>
           <input dir="ltr"
            type="password"
            id="password"
            placeholder={t("password_placeholder")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="toggle-password"
            onClick={() => togglePasswordVisibility("password")}
          >
          </span>
          <div className="error">{errors.password && t("error_message_password")}</div>
        </div>
        <div className="input-group">
          <label htmlFor="confirm-password">{t("confirm_password_label")}</label>
           <input dir="ltr"
            type="password"
            id="confirm-password"
            placeholder={t("confirm_password_placeholder")}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <span
            className="toggle-password"
            onClick={() => togglePasswordVisibility("confirm-password")}
          >
          </span>
          <div className="error">
            {errors.confirmPassword && t("error_message_confirm_password")}
          </div>
        </div>
        <div className="buttons">
          <button type="submit" className="sign-in">
            {t("sign_up")}
          </button>
          <Link to="/SignIn">
            <button type="button" className="sign-up">
              {t("sign_in")}
            </button>
          </Link>
        </div>
        <p style={{fontSize: '13px', marginTop: '20px', color: 'red'}}>{t("note_message_password")}</p>
      </form>
     
    </div>
    
            </div>
            <ToastContainer /> {/* عرض التوستات */}
            <p></p>
        </div>
        
    );
}

export default SignUp;
